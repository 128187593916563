<template>
  <StListbox
    v-model="currentMarketSpecifier"
    :action="isMobile ? 'click' : 'hover'"
    class="specifier-picker"
    :class="specifierClasses"
    data-t="specifier-picker"
  >
    <StListboxButton class="specifier-picker-button" :with-arrow="false">
      <div class="specifier-button-wrapper">
        <StIcon class="specifier-picker-arrow" name="chevron-top" size="8" />
        <div class="specifier-picker-title" data-t="current-specifier">
          <slot> {{ currentMarketSpecifier }}</slot>
        </div>
        <StIcon class="specifier-picker-arrow" name="chevron-down" size="8" />
      </div>
    </StListboxButton>

    <StListboxOptions
      class="specifier-picker-options"
      centered
      data-t="specifier-options"
    >
      <StListboxOption
        v-for="item in items"
        :key="item.id"
        class="specifier-picker-option"
        :value="{ ...item }"
        data-t="specifier-option"
      >
        {{ item.left }}
        {{ formatSpecifier(item.center) }}
        {{ item.right }}
      </StListboxOption>
    </StListboxOptions>
  </StListbox>
</template>

<script lang="ts" setup>
interface SpecifierList {
  center: number
  favourite: boolean
  left: string
  right: string
  specifiersString: string
  id: string
}

interface Props {
  modelValue: string
  items: SpecifierList[]
  size?: 'l' | 'm'
}

const props = withDefaults(defineProps<Props>(), {
  size: 'l',
})

const currentMarketSpecifier = ref<string>(props.modelValue)
const specifierClasses = computed(() => [props.size])

const emit = defineEmits<{
  (e: 'update:modelValue', option: string): void
}>()

function formatSpecifier(value: number) {
  if (value > 0) {
    const decimalCount = value.toString().split('.')?.[1]?.length ?? 1
    return value.toFixed(decimalCount)
  }
  return value
}

watch(
  () => currentMarketSpecifier.value,
  (newValue: string) => {
    emit('update:modelValue', newValue)
  },
)

const { isMobile } = usePlatform()
</script>

<style scoped>
.specifier-picker {
  --st-listbox-options-min-width: 56px;
  --specifier-picker-button-gap: 0px;

  &.l {
    --specifier-picker-button-gap: var(--spacing-050);
  }
}

.specifier-picker-options {
  padding: var(--spacing-050);
}

.specifier-picker-button {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  justify-content: center;

  width: 52px;
}

.specifier-button-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;

  width: 100%;

  text-overflow: ellipsis;
}

.specifier-picker-title {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-025, 2px);
  align-items: center;

  font: var(--desktop-text-xs-medium);
}

.specifier-picker-arrow {
  color: var(--text-tertiary);
}

.specifier-picker-option {
  justify-content: center;
  padding: var(--spacing-050);
  text-align: center;
  white-space: nowrap;
}
</style>
