<template>
  <div class="simple-market" data-t="simple-market">
    <OutcomeButton
      v-for="(outcome, idx) in outcomes"
      :key="outcome.uniqId || idx"
      :outcome="outcome"
      :market-status="market?.status ?? 0"
      v-bind="buttonProps"
    />
  </div>
</template>

<script setup lang="ts">
import type {
  FilterConfig,
  OutcomeInFilters,
} from 'markets-store/marketsbook/types'
import type { TMarket, TOutcome } from 'markets-store/types'
import { groupBy } from '@st/utils'
import OutcomeButton from '../../OutcomeButton/OutcomeButton.vue'
import { getMarketsFromConfigLine } from '../../../helpers'
import type { ButtonProps } from '../../../types'

interface Props {
  marketsFilter: FilterConfig
  markets: TMarket[]
  period?: number
  periodSpecifier?: string
  buttonProps?: ButtonProps
  /*
    forceMarkets -маркет для принудительной отрисовки без проверок конфига
  */
  forceMarkets?: TMarket | null
}

const props = withDefaults(defineProps<Props>(), {
  period: 0,
  periodSpecifier: '',
  markets: () => [],
  forceMarkets: null,
})

const defaultOutcome = Object.freeze({
  doubleOdds: ' – ',
  active: false,
  uniqId: 0,
}) as unknown as TOutcome

const market = computed(() => {
  if (props.forceMarkets) return props.forceMarkets

  const markets = getMarketsFromConfigLine({
    marketsFilter: props.marketsFilter,
    marketList: props.markets,
  })

  if (!markets) return null

  /* если доступен маркет для временного отрезка то отдаем его */
  if (props.periodSpecifier && props.period) {
    return markets.find(
      (m) =>
        String(m.specifiers[props.periodSpecifier]) === String(props.period),
    )
  }

  return markets.find((m) => m.status)
})

const outcomes = computed(() => {
  if (props.forceMarkets)
    return props.forceMarkets.outcomes.slice().sort((a, b) => a.id - b.id)
  if (!market.value) return [defaultOutcome, defaultOutcome, defaultOutcome]

  const marketOutcomes = groupBy(market.value.outcomes, (outcome) => outcome.id)
  const marketId = market.value.id as keyof typeof props.marketsFilter.outcomes

  const outcomesRules: OutcomeInFilters[] = !Array.isArray(
    props.marketsFilter.outcomes,
  )
    ? props.marketsFilter.outcomes[marketId]
    : props.marketsFilter.outcomes

  /*
    правила отрисовки ауткамов в маркете
    берется из конфига по значению "outcomesByMarketIds" (при наличии)
    или из поля "outcomes"
  */
  const outcomesByMarketId: OutcomeInFilters[] =
    props.marketsFilter.outcomesByMarketIds?.[marketId] ?? (outcomesRules || [])

  return outcomesByMarketId.map((outcId) => {
    if (!outcId || outcId === 'specifier') {
      return { computedState: 'hidden' } as unknown as TOutcome
    }

    return marketOutcomes.get(outcId)[0] || defaultOutcome
  })
})
</script>

<style scoped>
.simple-market {
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: space-between;
}
</style>
