<template>
  <transition name="st-listbox">
    <ul
      v-if="api.isListboxOpened.value"
      ref="optionsRef"
      role="listbox"
      aria-orientation="vertical"
      class="st-listbox-options"
      :class="styleClasses"
      :style="styleObject"
    >
      <slot />
    </ul>
  </transition>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, type Ref } from 'vue'
import {
  useFloating,
  flip,
  shift,
  arrow,
  hide,
  offset,
  autoUpdate,
} from '@floating-ui/vue'
import type { Option } from './types'
import { useListboxApi } from './composables'

type Placement = 'bottom' | 'top'

interface Props {
  placement?: Placement
  centered?: boolean
  maxHeight?: number
}

const props = withDefaults(defineProps<Props>(), {
  placement: 'bottom',
})

const api = useListboxApi('listboxOptions')
const optionsRef = ref<HTMLElement | null>()
const optionsArrowRef = ref<HTMLElement | null>()

function useFocusOption(
  options: Ref<Option[]>,
  focusedOption: Ref<Option | null>,
  setFocus: (option: Option) => void,
) {
  type Direction = 'previous' | 'next'

  const currentIndex = computed(() =>
    options.value.findIndex((item) => item.id === focusedOption.value?.id),
  )
  const focusOption = (direction: Direction) => {
    const previousIndex =
      direction === 'previous' && (currentIndex.value || 1) - 1
    const nextIndex =
      options.value.length !== currentIndex.value
        ? currentIndex.value + 1
        : options.value.length
    const newIndex = previousIndex || nextIndex || 0
    const newFocusedOption = options.value[newIndex]

    if (newFocusedOption) {
      setFocus(newFocusedOption)
    }
  }

  const nextOption = () => focusOption('next')
  const previousOption = () => focusOption('previous')

  return { nextOption, previousOption }
}

const { nextOption, previousOption } = useFocusOption(
  api.options,
  api.focusedOption,
  api.setFocus,
)
const { x, y, strategy } = useFloating(api.buttonRef, optionsRef, {
  placement: props.placement,
  middleware: [
    offset(({ rects: { reference, floating } }) => {
      const centerPosition = -reference.height / 2 - floating.height / 2
      return props.centered ? centerPosition : 2
    }),
    flip(),
    shift(),
    hide(),
    arrow({ element: optionsArrowRef }),
  ],
  whileElementsMounted: autoUpdate,
})

const styleClasses = computed(() => [
  {
    opened: api?.isListboxOpened.value,
  },
])
const styleObject = computed(() => ({
  position: strategy.value,
  top: `${y.value ?? 0}px`,
  left: `${x.value ?? 0}px`,
}))

function handleKeys(e: KeyboardEvent) {
  e.preventDefault()
  switch (e.key) {
    case 'Escape':
      api.closeListbox()
      break
    case 'Enter':
      api.selectOption(api.focusedOption.value)
      break
    case 'ArrowUp':
      previousOption()
      break
    case 'ArrowDown':
      nextOption()
      break
    default:
      break
  }
}

// @TODO Переписать, ломается серверный рендеринг
watchEffect(() => {
  try {
    if (api.isListboxOpened.value) {
      document.addEventListener('keydown', handleKeys)
    } else {
      document.removeEventListener('keydown', handleKeys)
    }
  } catch (err) {
    //
  }
})

const maxHeightStyle = computed(() => `${props.maxHeight || 192}px`)
</script>

<style>
:root {
  --st-listbox-options-min-width: 56px;
}
</style>

<style scoped>
.st-listbox-options {
  cursor: pointer;

  z-index: 2;

  overflow: auto;
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  /* stylelint-disable */
  min-width: var(--st-listbox-options-min-width);
  max-height: v-bind(maxHeightStyle);
  /* stylelint-enable */
  margin: 0;
  padding: 0;

  list-style-type: none;

  background: var(--background-secondary);
  border-radius: var(--border-radius-100);
  box-shadow:
    0 8px 12px -4px rgb(0 0 0 / 32%),
    0 16px 24px -4px rgb(0 0 0 / 24%);

  transition: opacity 0.3s ease-out;

  &.opened {
    z-index: 10;
  }
}

.st-listbox-enter-active,
.st-listbox-leave-active {
  transform: translateY(0);
  opacity: 1;
  transition:
    opacity 0.2s,
    transform 0.2s;
}

.st-listbox-enter-from,
.st-listbox-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}
</style>
