<template>
  <button
    ref="buttonRef"
    class="st-listbox-button"
    @mousemove="handleMouseEnter"
    @click="handleClick"
  >
    <div class="st-listbox-button-label">
      <slot :is-active="api.isListboxOpened"></slot>
    </div>
    <StIcon v-if="props.withArrow" name="chevron-down" size="8" />
  </button>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useListboxApi } from './composables'

interface Props {
  withArrow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  withArrow: true,
})

const api = useListboxApi('listboxButton')
const buttonRef = ref<HTMLButtonElement | null>()

function toggleListbox() {
  if (api.isListboxOpened.value) {
    api.closeListbox()
  } else {
    api.openListbox()
  }
}

function handleMouseEnter() {
  if (api.listboxAction.value === 'hover') {
    api.openListbox()
  }
}

function handleClick() {
  if (api.listboxAction.value === 'click') {
    toggleListbox()
  }
}

onMounted(() => {
  if (buttonRef.value) {
    api.registerButton(buttonRef.value)
  } else {
    Error("ListBoxButton couldn't register")
  }
})

onBeforeUnmount(() => {
  if (buttonRef.value) {
    api.unregisterButton()
  } else {
    Error("ListBoxButton couldn't unregister")
  }
})
</script>

<style scoped>
.st-listbox-button {
  cursor: pointer;

  z-index: 1;

  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 0;

  font: var(--desktop-text-xs-medium);
  color: var(--text-link);
  white-space: nowrap;

  background-color: transparent;
  border: none;
  box-shadow: none;
}

.st-listbox-button-label {
  width: 100%;
}
</style>
