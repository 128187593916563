<template>
  <div ref="listboxRef" class="st-listbox" @mouseleave="handleMouseLeave">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, provide } from 'vue'
import { onClickOutside } from '@st/use/composables'
import type { Action, ListboxApi, Option } from './types'
import { listboxApi } from './types'

const emit = defineEmits<{
  (e: 'update:modelValue', option: Option): void
}>()

interface Props {
  modelValue: Record<string, unknown> | string | number | undefined
  disabled?: boolean
  /** Имя свойства, использующееся в качестве уникального для переданных опций */
  by?: string
  action?: Action
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  by: 'id',
  action: 'click',
})

const isListboxOpened = ref<ListboxApi['isListboxOpened']['value']>(false)
const listboxAction = ref<Action>(props.action)
const listboxRef = ref<ListboxApi['listboxRef']['value']>(null)
const buttonRef = ref<ListboxApi['buttonRef']['value']>(null)
const optionsRef = ref<ListboxApi['optionsRef']['value']>(null)
const optionRefs = ref<ListboxApi['optionRefs']['value']>([])
const options = ref<ListboxApi['options']['value']>([])
const selectedOption = computed<ListboxApi['selectedOption']['value']>(() => {
  if (props.modelValue === undefined) {
    return null
  }

  const key =
    typeof props.modelValue !== 'number' && typeof props.modelValue !== 'string'
      ? props.modelValue[props.by]
      : props.modelValue

  return options?.value.find((item) => item[props.by] === key) || null
})
const focusedOption = ref<ListboxApi['focusedOption']['value']>(
  selectedOption.value,
)

function setFocus(option: Option | null) {
  focusedOption.value = option
}
function registerButton(buttonEl: HTMLButtonElement) {
  buttonRef.value = buttonEl
}
function unregisterButton() {
  buttonRef.value = null
}
function registerOption(optionEl: HTMLLIElement, option: Option) {
  options.value.push(option)
  optionRefs.value.push(optionEl)
}
function unregisterOption(optionEl: HTMLLIElement) {
  const index = optionRefs.value.indexOf(optionEl)

  if (index !== -1) {
    optionRefs.value.splice(index, 1)
  }
}
function openListbox() {
  if (props.disabled) return
  if (isListboxOpened.value === true) return
  isListboxOpened.value = true
  setFocus(selectedOption.value)
}
function closeListbox() {
  if (props.disabled) return
  if (isListboxOpened.value === false) return
  isListboxOpened.value = false
}
function emitSelect(option: Option) {
  emit('update:modelValue', option)
}

const api: ListboxApi = {
  isListboxOpened,
  listboxAction,
  listboxRef,
  buttonRef,
  optionsRef,
  optionRefs,
  options,
  selectedOption,
  focusedOption,
  registerButton,
  unregisterButton,
  registerOption,
  unregisterOption,
  openListbox,
  closeListbox,
  setFocus,
  selectOption: () => ({}),
}
provide(listboxApi, api)

function selectOption(option: Option) {
  const value = option[props.by] || option

  api.closeListbox()
  emitSelect(value as Option)
}

api.selectOption = selectOption

function handleMouseLeave() {
  if (api.listboxAction.value === 'hover') {
    api.closeListbox()
  }
}

onClickOutside(listboxRef, api.closeListbox)
</script>

<style scoped>
.st-listbox {
  position: relative;
  display: flex;
  width: 100%;
}
</style>
