import type { TSportEvent } from 'markets-store'
import { SPORT_IDS } from 'markets-store/constants'
import {
  MATCH_STATUS_CODES_BY_BASEBALL,
  MATCH_STATUS_CODES_BY_CRICKET,
} from './constants'

export function useEventServer(event: TSportEvent | Ref<TSportEvent>) {
  function getServerByBaseball() {
    const status = unref(event)?.match?.status || 0

    const isServing = (qualifier: 'home' | 'away') =>
      MATCH_STATUS_CODES_BY_BASEBALL[qualifier].has(status)

    if (isServing('home')) return 1
    if (isServing('away')) return 2
    return 0
  }

  function getServerByCricket() {
    const status = unref(event)?.match?.status
    const provider = unref(event)?.provider

    const isServing = (qualifier: 'home' | 'away') =>
      status ? MATCH_STATUS_CODES_BY_CRICKET[qualifier].has(status) : false

    if (provider !== 1) return 0

    if (isServing('home')) return 1
    if (isServing('away')) return 2

    return 0
  }

  const server = computed(() => {
    const unrefEvent = unref(event)

    if (unrefEvent.sportId === SPORT_IDS.BASEBALL) return getServerByBaseball()
    if (unrefEvent.sportId === SPORT_IDS.CRICKET) return getServerByCricket()

    const currentServer1 = unrefEvent.match_stats?.current_server
    const currentServer2 = unrefEvent.match?.stats?.current_server
    const possession = unrefEvent.match_stats?.possession
    const visit = unrefEvent?.match?.stats?.visit
    const delivery = unrefEvent?.match?.stats?.delivery

    return currentServer1 || currentServer2 || possession || delivery || visit
  })

  return { server }
}
