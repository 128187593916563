import type {
  FilterConfig,
  MarketsInFilters,
} from 'markets-store/marketsbook/types'
import { groupBy } from '@st/utils'
import type { TMarket } from 'markets-store'

export interface GetMarketsParams {
  marketsFilter: FilterConfig | null
  marketList?: TMarket[]
  line?: keyof MarketsInFilters
}

export function getMarketsFromConfigLine({
  marketsFilter,
  marketList,
  line,
}: GetMarketsParams): TMarket[] | undefined {
  if (!marketsFilter || !marketList) return []

  const lineNmae = line ?? marketsFilter.lineName ?? 'main'
  const lineConfig = marketsFilter.markets?.[lineNmae] || []

  const groupedMarkets = groupBy(marketList, (item) => item.id)
  const marketsByIds = lineConfig.map((marketId) =>
    groupedMarkets.get(marketId),
  )

  /* 
    выбираем для отрисовки первый доступный маркет 
    или группу маркетов из конфига 
  */
  return marketsByIds.filter((arr) => arr.length)[0]
}
