import type { Ref, InjectionKey } from 'vue'

export type Option = Record<string, unknown>
export type Action = 'click' | 'hover'

export interface ListboxApi {
  isListboxOpened: Ref<boolean>
  listboxAction: Ref<Action>

  listboxRef: Ref<HTMLDivElement | null>
  buttonRef: Ref<HTMLButtonElement | null>
  optionsRef: Ref<HTMLUListElement | null>
  optionRefs: Ref<HTMLLIElement[]>
  options: Ref<Option[]>
  focusedOption: Ref<Option | null>
  selectedOption: Ref<Option | null>

  registerButton(button: HTMLButtonElement): void
  unregisterButton(): void
  registerOption(optionEl: HTMLLIElement, option: Option): void
  unregisterOption(optionEl: HTMLLIElement): void
  openListbox(): void
  closeListbox(): void
  setFocus(option: Option): void
  selectOption(option: Option | null): void
}

export const listboxApi = Symbol('ListboxApi') as InjectionKey<ListboxApi>
