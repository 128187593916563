<template>
  <div class="total-market" data-t="total-market">
    <OutcomeButton
      :key="homeAway.home.uniqId"
      :outcome="homeAway.home"
      :market-status="currentMarket?.status ?? 0"
      v-bind="buttonProps"
    />
    <template v-if="currentMarket">
      <SpecifierPicker
        v-if="specifierValue && specifiersOutput.length > 1"
        v-model="specifierValue"
        :items="specifiersOutput"
      >
        <span v-if="props.charSpecifiers" class="char-specifier">
          {{ i18n.t('markets.less') }}
        </span>
        <span>{{ formattedSpecifier }}</span>
        <span v-if="props.charSpecifiers" class="char-specifier">
          {{ i18n.t('markets.more') }}
        </span>
      </SpecifierPicker>

      <span
        v-if="specifiersOutput.length === 1"
        class="single-specifier"
        data-t="specifiers"
      >
        <span v-if="props.charSpecifiers" class="char-specifier">
          {{ i18n.t('markets.less') }}
        </span>
        {{ formattedSpecifier }}
        <span v-if="props.charSpecifiers" class="char-specifier">
          {{ i18n.t('markets.more') }}
        </span>
      </span>
    </template>
    <span v-else class="no-market" data-t="specifiers"> – </span>

    <OutcomeButton
      :key="homeAway.home.uniqId"
      :outcome="homeAway.away"
      :market-status="currentMarket?.status ?? 0"
      v-bind="buttonProps"
    />
  </div>
</template>

<script setup lang="ts">
import type { FilterConfig } from 'markets-store/marketsbook/types'
import type { TMarket } from 'markets-store/types'
import { useSpecifierGroup } from '../../../composables'
import OutcomeButton from '../../OutcomeButton/OutcomeButton.vue'
import SpecifierPicker from '../../SpecifierPicker/SpecifierPicker.vue'
import type { ButtonProps } from '../../../types'

interface SpecifierList {
  center: number
  favourite: boolean
  left: string
  right: string
  specifiersString: string
  market: TMarket
  id: string
}

interface Props {
  marketsFilter: FilterConfig | null
  markets: TMarket[]
  period?: number
  periodSpecifier?: string
  /*
    forceMarkets -маркеты для принудительной отрисовки без проверок конфига
  */
  forceMarkets?: TMarket[]
  charSpecifiers?: boolean
  buttonProps?: ButtonProps
}

const i18n = useI18n()

const props = withDefaults(defineProps<Props>(), {
  period: 0,
  periodSpecifier: '',
  forceMarkets: () => [],
})
const { marketsFilter, markets, forceMarkets, period, periodSpecifier } =
  toRefs(props)

const { currentMarket, homeAway, specifiersFiltered, specifierValue } =
  useSpecifierGroup(
    { marketsFilter, markets, forceMarkets, period, periodSpecifier },
    'total',
  )

const specifiersOutput = computed<SpecifierList[]>(() =>
  specifiersFiltered.value.map((market) => {
    const specifier = market.specifiers.total ?? 0
    const [left, center, right] = ['', Math.abs(Number(specifier)), '']
    return {
      favourite: market.favourite,
      left,
      center,
      right,
      market,
      specifiersString: market.specifiersString,
      id: market.specifiersString,
    }
  }),
)

const formattedSpecifier = computed(() => {
  if (!currentMarket.value) return currentMarket.value

  const num = Number(currentMarket.value.specifiers.total)
  const decimalCount = num.toString().split('.')?.[1]?.length ?? 1
  return num.toFixed(decimalCount)
})
</script>

<style scoped>
.total-market {
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: space-between;

  .no-market,
  .single-specifier {
    min-width: 52px;
    font: var(--desktop-text-xs-medium);
    text-align: center;
    white-space: nowrap;
  }

  .char-specifier {
    overflow: hidden;
    display: inline-flex;

    max-width: 10px;

    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .single-specifier {
    color: var(--text-link);
  }
}
</style>
