import type { FilterConfig } from 'markets-store/marketsbook/types'
import type { TMarket, TOutcome } from 'markets-store/types'
import { MARKET_ALLOWED_STATES } from 'markets-store/constants'
import { sortBy, groupBy } from '@st/utils'
import { getMarketsFromConfigLine } from '../../helpers'

interface Props {
  marketsFilter: Ref<FilterConfig | null>
  markets?: Ref<TMarket[]>
  /* 
    маркеты для принудительной отрисовки
  */
  forceMarkets?: Ref<TMarket[]>
  period: Ref<number>
  periodSpecifier: Ref<string>
}

type SpecifierType = 'total' | 'hcp'

const defaultOutcome = Object.freeze({
  doubleOdds: ' – ',
  active: false,
}) as TOutcome
const defaultHomeAway = {
  home: defaultOutcome,
  away: defaultOutcome,
}

const MAX_GROUP_LENGTH = 5
const LEFT_GROUP_SIZE_FROM_CURRENT = 2
const RIGHT_GROUP_SIZE_FROM_CURRENT = 3

export function useSpecifierGroup(
  { marketsFilter, markets, forceMarkets, period, periodSpecifier }: Props,
  type: SpecifierType = 'total',
) {
  const marketsGroup = computed(() => {
    if (forceMarkets?.value?.length) {
      return forceMarkets.value
    }

    const marketsLine = getMarketsFromConfigLine({
      marketsFilter: marketsFilter.value,
      marketList: markets?.value,
    })

    if (marketsLine) {
      const localMarkets = Array.isArray(marketsLine)
        ? marketsLine
        : [marketsLine]
      return localMarkets.filter(
        (m) =>
          !periodSpecifier.value ||
          !period.value ||
          m.specifiers[periodSpecifier.value] === String(period.value),
      )
    }
    return []
  })

  const specifiersSorted = computed(() =>
    sortBy(marketsGroup.value, (group) => [group.specifiers[type] ?? 0]),
  )

  const favoriteMarket = computed(() => {
    if (!marketsGroup.value?.length) return null
    return marketsGroup.value.reduce((prev, cur) =>
      prev.outcomesRange < cur.outcomesRange ? prev : cur,
    )
  })

  /*
    получение приоритетного специфаера
    и сопоставление ближайших к нему значений
  */
  const specifiersFiltered = computed(() => {
    const specifiersSortedLength = specifiersSorted.value.length
    if (specifiersSortedLength > MAX_GROUP_LENGTH) {
      const currentSpecifier = favoriteMarket.value?.specifiersString || ''
      let currentPos = specifiersSorted.value.findIndex(
        (sp) => sp.specifiersString === currentSpecifier,
      )
      if (currentPos < 0) currentPos = 0

      if (
        LEFT_GROUP_SIZE_FROM_CURRENT < currentPos &&
        specifiersSorted.value[currentPos + LEFT_GROUP_SIZE_FROM_CURRENT]
      )
        return specifiersSorted.value.slice(
          currentPos - LEFT_GROUP_SIZE_FROM_CURRENT,
          currentPos + RIGHT_GROUP_SIZE_FROM_CURRENT,
        )

      if (currentPos <= LEFT_GROUP_SIZE_FROM_CURRENT)
        return specifiersSorted.value.slice(0, MAX_GROUP_LENGTH)

      if (currentPos === specifiersSortedLength - 1)
        return specifiersSorted.value.slice(
          specifiersSortedLength - MAX_GROUP_LENGTH,
          specifiersSortedLength + 1,
        )

      return specifiersSorted.value.slice(
        currentPos - RIGHT_GROUP_SIZE_FROM_CURRENT,
        currentPos + LEFT_GROUP_SIZE_FROM_CURRENT,
      )
    }

    return specifiersSorted.value
  })

  const specifierValue = ref()
  const currentMarket = computed(() => {
    if (!marketsGroup.value) return null

    const userPickedOutcome = marketsGroup.value.find(
      (m) => m.specifiersString === specifierValue.value,
    )

    if (
      userPickedOutcome &&
      MARKET_ALLOWED_STATES.includes(userPickedOutcome?.status)
    )
      return userPickedOutcome

    return favoriteMarket.value
  })

  const outcomes = computed(() => currentMarket.value?.outcomes)

  const homeAway = computed(() => {
    if (!outcomes.value) return defaultHomeAway

    if (!Array.isArray(marketsFilter.value?.outcomes)) {
      const localHomeOutcomes = marketsFilter.value?.outcomes.home as number
      const localAwayOutcomes = marketsFilter.value?.outcomes.away as number

      const groupedOutcomes = groupBy(outcomes.value, (outcome) => outcome.id)
      return {
        home: (groupedOutcomes.get(localHomeOutcomes)[0] ||
          defaultOutcome) as TOutcome,
        away: (groupedOutcomes.get(localAwayOutcomes)[0] ||
          defaultOutcome) as TOutcome,
      }
    }

    return defaultHomeAway
  })

  watch(
    () => currentMarket.value,
    (value) => {
      if (!value && marketsGroup.value?.[0]) {
        const market = marketsGroup.value[0]
        // @ts-expect-error update method from market-store
        market.update({ favourite: 1, status: market.status, outcomes: [] })
      }
    },
    {
      immediate: true,
    },
  )

  watch(
    () => favoriteMarket.value,
    (market) => {
      const specifierString = market?.specifiers?.[type]
      if (specifierString || specifierString === 0) {
        specifierValue.value = `${type}=${specifierString}`
      }
    },
    {
      immediate: true,
    },
  )

  return {
    homeAway,
    currentMarket,
    specifierValue,
    specifiersFiltered,
  }
}
