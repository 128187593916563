<template>
  <li
    ref="optionRef"
    role="option"
    :aria-selected="isSelected"
    class="st-listbox-option"
    :class="styleClasses"
    @mouseover="api.setFocus(value)"
    @click="api.selectOption(value)"
  >
    <slot :selected="isSelected" />
  </li>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import type { Option } from './types'
import { useListboxApi } from './composables'

interface Props {
  value: Option
}

const props = defineProps<Props>()

const api = useListboxApi('listboxOption')
const option = typeof props.value === 'string' ? props.value : props.value.id
const optionRef = ref<HTMLLIElement | null>(null)

const isSelected = computed<boolean>(
  () => api.selectedOption.value?.id === option,
)
const isFocused = computed<boolean>(
  () => api.focusedOption.value?.id === option,
)
const styleClasses = computed(() => [
  {
    selected: isSelected.value,
    focused: isFocused.value,
  },
])

onMounted(() => {
  if (optionRef.value) {
    api.registerOption(optionRef.value, props.value)
  } else {
    Error("ListBoxOption couldn't register")
  }
})

onUnmounted(() => {
  if (optionRef.value) {
    api.unregisterOption(optionRef.value)
  } else {
    Error("ListBoxOption couldn't unregister")
  }
})
</script>

<style scoped>
.st-listbox-option {
  display: flex;
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-primary);

  border-radius: var(--border-radius-050);

  &.focused {
    background-color: var(--background-tertiary);
  }

  &.selected {
    color: var(--text-link);
  }
}
</style>
