import { inject } from 'vue'
import { listboxApi } from '../types'

export function useListboxApi(component: string) {
  const context = inject(listboxApi, null)

  if (context === null) {
    const err = new Error(
      `<${component} /> is missing a parent <StListbox /> component.`,
    )
    if (Error.captureStackTrace) Error.captureStackTrace(err, useListboxApi)
    throw err
  }

  return context
}
