<template>
  <div class="handicap-market" data-t="handicap-market">
    <OutcomeButton
      :key="homeAway.home.uniqId"
      :outcome="homeAway.home"
      :market-status="currentMarket?.status ?? 0"
      v-bind="buttonProps"
    />
    <template v-if="currentMarket">
      <SpecifierPicker
        v-if="specifierValue && specifiersOutput.length > 1"
        v-model="specifierValue"
        :items="specifiersOutput"
      >
        {{ specifier.left }}
        <span>{{ formattedSpecifier }}</span>
        {{ specifier.right }}
      </SpecifierPicker>

      <span
        v-if="specifiersOutput.length === 1"
        class="single-specifier"
        data-t="specifiers"
      >
        {{ specifier.left }} {{ formattedSpecifier }} {{ specifier.right }}
      </span>
    </template>
    <span v-else class="no-market" data-t="specifiers"> – </span>

    <OutcomeButton
      :key="homeAway.home.uniqId"
      :outcome="homeAway.away"
      :market-status="currentMarket?.status ?? 0"
      v-bind="buttonProps"
    />
  </div>
</template>

<script setup lang="ts">
import type { FilterConfig } from 'markets-store/marketsbook/types'
import type { TMarket } from 'markets-store/types'
import { useSpecifierGroup } from '../../../composables'
import OutcomeButton from '../../OutcomeButton/OutcomeButton.vue'
import SpecifierPicker from '../../SpecifierPicker/SpecifierPicker.vue'
import type { ButtonProps } from '../../../types'

interface SpecifierList {
  center: number
  favourite: boolean
  left: string
  right: string
  specifiersString: string
  market: TMarket
  id: string
}

interface Props {
  marketsFilter: FilterConfig
  markets: TMarket[]
  period?: number
  periodSpecifier?: string
  /*
    forceMarkets -маркеты для принудительной отрисовки без проверок конфига
  */
  forceMarkets?: TMarket[]
  buttonProps?: ButtonProps
}

const props = withDefaults(defineProps<Props>(), {
  forceMarkets: () => [],
  period: 0,
  periodSpecifier: '',
})

const { marketsFilter, markets, forceMarkets, period, periodSpecifier } =
  toRefs(props)

const { currentMarket, homeAway, specifiersFiltered, specifierValue } =
  useSpecifierGroup(
    { marketsFilter, markets, forceMarkets, period, periodSpecifier },
    'hcp',
  )

const specifiersOutput = computed<SpecifierList[]>(() =>
  specifiersFiltered.value.map((market) => {
    const specifier = Number(market.specifiers.hcp) ?? 0
    let left = ''
    let right = ''
    const center = Math.abs(specifier)

    if (specifier) {
      if (Number(specifier) < 0) {
        left = ' – '
        right = ' + '
      } else {
        right = ' – '
        left = ' + '
      }
    }

    return {
      favourite: market.favourite,
      left,
      center,
      right,
      market,
      specifiersString: market.specifiersString,
      id: market.specifiersString,
    }
  }),
)
const specifier = computed(() => {
  const out: {
    left: string
    center: string | number
    right: string
  } = { left: '', center: '', right: '' }
  if (!currentMarket.value) return out
  const localSpecifier = Number(currentMarket.value.specifiers.hcp)

  if (localSpecifier < 0) {
    out.left = '–'
    out.right = '+'
  }
  if (localSpecifier > 0) {
    out.left = '+'
    out.right = '–'
  }
  out.center = Math.abs(localSpecifier)

  return out
})
const formattedSpecifier = computed(() => {
  if (!specifier.value.center) return specifier.value.center

  const num = Number(specifier.value.center)
  const decimalCount = num.toString().split('.')?.[1]?.length ?? 1
  return num.toFixed(decimalCount)
})
</script>

<style scoped>
.handicap-market {
  display: flex;
  flex: 1 0 0;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: space-between;

  .no-market,
  .single-specifier {
    min-width: 52px;
    font: var(--desktop-text-xs-medium);
    text-align: center;
    white-space: nowrap;
  }

  .single-specifier {
    color: var(--text-primary);
  }
}
</style>
